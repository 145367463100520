<script setup lang="ts">
const localePath = useLocalePath()

interface Props {
  error: {
    statusCode: number
  }
}
const props = defineProps<Props>()

onMounted(() => {
  console.log(props.error.statusCode)
  return navigateTo(localePath({ name: '404' }))
})
</script>
