import dayjs from 'dayjs'
import * as holiday_jp from '@holiday-jp/holiday_jp'
import type * as v from 'valibot'
import type { businessHours } from '~/schema'

type BusinessHours = v.InferOutput<typeof businessHours>

export default defineNuxtPlugin((nuxtApp) => {
  const { $i18n } = nuxtApp
  // eslint-disable-next-line
  // @ts-expect-error
  const { t } = $i18n

  return {
    provide: {
      // 営業時間
      businessHour: (
        openTime: string,
        closeTime: string,
        lastOrderTime: string,
      ) => {
        if (openTime === '00:00' && closeTime === '24:00')
          return t('plugins.businessHours.open24hours')

        let time = `${openTime} - ${closeTime}`
        if (lastOrderTime) time = time + `（L.O. ${lastOrderTime}）`
        return time
      },
      // 次の営業時間
      nextBusinessHour: (businessHours: BusinessHours) => {
        const { $formatDate } = useNuxtApp()

        // 当日から 7 日間
        const days = [...Array(7).keys()].map((num) => dayjs().add(num, 'day'))

        // 次の営業時間
        let nextTime = ''

        // 営業時間
        const {
          specialHours,
          holidayRegularHour,
          dayBeforeHolidayRegularHour,
          regularHours,
        } = businessHours

        for (const [index, day] of days.entries()) {
          const youbi = day.format('dddd').toLowerCase()
          const onTheDay = index === 0

          // 特別営業時間かどうかの判定
          specialHours.forEach((specialHour) => {
            if (!specialHour.open) return // 営業日ではない

            const isSame = day.isSame(specialHour.date, 'day')
            if (!isSame) return // 当日に該当しない

            for (const time of specialHour.times) {
              if (!onTheDay) {
                nextTime = time.openTime
                break
              }
              const openDateTime = dayjs(
                `${$formatDate(day.format(), 'YYYY/MM/DD')} ${time.openTime}`,
              )
              const result = openDateTime.isAfter(day)
              if (result) {
                nextTime = time.openTime
                break
              }
            }
          })
          if (nextTime) break

          // 祝日かどうか
          const isHoliday = holiday_jp.isHoliday(day.toDate())
          if (isHoliday && holidayRegularHour && holidayRegularHour.open) {
            for (const time of holidayRegularHour.times) {
              if (!onTheDay) {
                nextTime = time.openTime
                break
              }
              const openDateTime = dayjs(
                `${$formatDate(day.format(), 'YYYY/MM/DD')} ${time.openTime}`,
              )
              const result = openDateTime.isAfter(day)
              if (result) {
                nextTime = time.openTime
                break
              }
            }
            if (nextTime) break
          }

          // 祝前日かどうか
          const nextDay = day.add(1, 'days')
          const isDayBeforeHoliday = holiday_jp.isHoliday(nextDay.toDate())
          if (
            isDayBeforeHoliday &&
            dayBeforeHolidayRegularHour &&
            dayBeforeHolidayRegularHour.open
          ) {
            for (const time of dayBeforeHolidayRegularHour.times) {
              if (!onTheDay) {
                nextTime = time.openTime
                break
              }
              const openDateTime = dayjs(
                `${$formatDate(day.format(), 'YYYY/MM/DD')} ${time.openTime}`,
              )
              const result = openDateTime.isAfter(day)
              if (result) {
                nextTime = time.openTime
                break
              }
            }
            if (nextTime) break
          }

          // 通常営業時間
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const regularHour = regularHours[youbi]
          if (!regularHour.open) continue
          for (const time of regularHour.times) {
            if (!onTheDay) {
              nextTime = time.openTime
              break
            }
            const openDateTime = dayjs(
              `${$formatDate(day.format(), 'YYYY/MM/DD')} ${time.openTime}`,
            )
            const result = openDateTime.isAfter(day)
            if (result) {
              nextTime = time.openTime
              break
            }
          }
          if (nextTime) break
        }

        return t('plugins.businessHours.openingHours', { nextTime })
      },
      // 営業状態（営業中 or 営業時間外）
      businessDayStatus: (businessHours: BusinessHours) => {
        const { $formatDate, $businessHour, $nextBusinessHour } = useNuxtApp()
        if (!import.meta.client) return ''

        let status = ''
        let hours = ''

        // 現在日時を取得
        const today = dayjs()
        const youbi = today.format('dddd').toLowerCase()

        // 特別営業時間かどうかの判定
        const { specialHours } = businessHours
        specialHours.forEach((specialHour) => {
          const isSame = today.isSame(specialHour.date, 'day')
          if (!isSame) return

          status = t('plugins.businessHours.closed')
          if (specialHour.open) {
            for (const time of specialHour.times) {
              const openDateTime = dayjs(
                `${$formatDate(today.format(), 'YYYY/MM/DD')} ${time.openTime}`,
              )
              const closeDateTime = dayjs(
                `${$formatDate(today.format(), 'YYYY/MM/DD')} ${time.closeTime}`,
              )
              const result = today.isBetween(openDateTime, closeDateTime)
              if (result) {
                status = t('plugins.businessHours.open')
                hours = $businessHour(
                  time.openTime,
                  time.closeTime,
                  time.lastOrderTime,
                )
                break
              }
            }
          }
        })
        if (status) {
          if (status === t('plugins.businessHours.closed')) {
            hours = $nextBusinessHour(businessHours)
          }
          return status === t('plugins.businessHours.open')
            ? `${status}<span>${hours}</span>`
            : `${status} - ${hours}`
        }

        // 祝日かどうか, 祝日の場合は営業中かどうか
        const isHoliday = holiday_jp.isHoliday(today.toDate())
        const { holidayRegularHour } = businessHours
        if (isHoliday && holidayRegularHour && holidayRegularHour.open) {
          status = t('plugins.businessHours.closed')
          for (const time of holidayRegularHour.times) {
            const openDateTime = dayjs(
              `${$formatDate(today.format(), 'YYYY/MM/DD')} ${time.openTime}`,
            )
            const closeDateTime = dayjs(
              `${$formatDate(today.format(), 'YYYY/MM/DD')} ${time.closeTime}`,
            )
            const result = today.isBetween(openDateTime, closeDateTime)
            if (result) {
              status = t('plugins.businessHours.open')
              hours = $businessHour(
                time.openTime,
                time.closeTime,
                time.lastOrderTime,
              )
              break
            }
          }
        }
        if (status) {
          if (status === t('plugins.businessHours.closed')) {
            hours = $nextBusinessHour(businessHours)
          }
          return status === t('plugins.businessHours.open')
            ? `${status}<span>${hours}</span>`
            : `${status} - ${hours}`
        }

        // 祝前日かどうか, 祝前日の場合は営業中かどうか
        const nextDay = today.add(1, 'days')
        const isDayBeforeHoliday = holiday_jp.isHoliday(nextDay.toDate())
        const { dayBeforeHolidayRegularHour } = businessHours
        if (
          isDayBeforeHoliday &&
          dayBeforeHolidayRegularHour &&
          dayBeforeHolidayRegularHour.open
        ) {
          status = t('plugins.businessHours.closed')
          for (const time of dayBeforeHolidayRegularHour.times) {
            const openDateTime = dayjs(
              `${$formatDate(nextDay.format(), 'YYYY/MM/DD')} ${time.openTime}`,
            )
            const closeDateTime = dayjs(
              `${$formatDate(nextDay.format(), 'YYYY/MM/DD')} ${time.closeTime}`,
            )
            const result = nextDay.isBetween(openDateTime, closeDateTime)
            if (result) {
              status = t('plugins.businessHours.open')
              hours = $businessHour(
                time.openTime,
                time.closeTime,
                time.lastOrderTime,
              )
              break
            }
          }
        }
        if (status) {
          if (status === t('plugins.businessHours.closed')) {
            hours = $nextBusinessHour(businessHours)
          }
          return status === t('plugins.businessHours.open')
            ? `${status}<span>${hours}</span>`
            : `${status} - ${hours}`
        }

        // それ以外のいずれの曜日に合致するか
        if (!businessHours.regularHours) return
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const regularHour = businessHours.regularHours[youbi]
        if (regularHour && regularHour.open) {
          status = t('plugins.businessHours.closed')
          for (const time of regularHour.times) {
            const openDateTime = dayjs(
              `${$formatDate(today.format(), 'YYYY/MM/DD')} ${time.openTime}`,
            )
            const closeDateTime = dayjs(
              `${$formatDate(today.format(), 'YYYY/MM/DD')} ${time.closeTime}`,
            )
            const result = today.isBetween(openDateTime, closeDateTime)
            if (result) {
              status = t('plugins.businessHours.open')
              hours = $businessHour(
                time.openTime,
                time.closeTime,
                time.lastOrderTime,
              )
              break
            }
          }
        }
        if (status !== t('plugins.businessHours.open')) {
          hours = $nextBusinessHour(businessHours)
        }
        return status === t('plugins.businessHours.open')
          ? `${status}<span>${hours}</span>`
          : `${t('plugins.businessHours.closed')} - ${hours}`
      },
    },
  }
})
