import dayjs from 'dayjs'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      formatDate: (value: string, format: string = 'YYYY.MM.DD') => {
        const date = dayjs(value)
        return date.format(format)
      },
      formatTime: (value: string, format: string = 'HH:mm') => {
        const date = dayjs(value)
        return date.format(format)
      },
    },
  }
})
