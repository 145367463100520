import { default as _4041FuVVa8Di7Meta } from "/tmp/nuxt/pages/404.vue?macro=true";
import { default as indexGwkaKndjplMeta } from "/tmp/nuxt/pages/index.vue?macro=true";
import { default as indexKK9HzAr6KFMeta } from "/tmp/nuxt/pages/stores/[storeId]/index.vue?macro=true";
import { default as _91postId_93zy59HAPN8DMeta } from "/tmp/nuxt/pages/stores/[storeId]/posts/[postId].vue?macro=true";
import { default as indexyxguav00gIMeta } from "/tmp/nuxt/pages/stores/[storeId]/posts/index.vue?macro=true";
import { default as indexueTeEFROiUMeta } from "/tmp/nuxt/pages/stores/index.vue?macro=true";
import { default as component_45stub1mojfLEhoXMeta } from "/tmp/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stub1mojfLEhoX } from "/tmp/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: "404___ja",
    path: "/404",
    component: () => import("/tmp/nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "index___ja",
    path: "/",
    component: () => import("/tmp/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "stores-storeId___ja",
    path: "/stores/:storeId()",
    component: () => import("/tmp/nuxt/pages/stores/[storeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "stores-storeId-posts-postId___ja",
    path: "/stores/:storeId()/posts/:postId()",
    component: () => import("/tmp/nuxt/pages/stores/[storeId]/posts/[postId].vue").then(m => m.default || m)
  },
  {
    name: "stores-storeId-posts___ja",
    path: "/stores/:storeId()/posts",
    component: () => import("/tmp/nuxt/pages/stores/[storeId]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "stores___ja",
    path: "/stores",
    component: () => import("/tmp/nuxt/pages/stores/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub1mojfLEhoXMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub1mojfLEhoX
  },
  {
    name: component_45stub1mojfLEhoXMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stub1mojfLEhoX
  },
  {
    name: component_45stub1mojfLEhoXMeta?.name,
    path: "/ja-sitemap.xml",
    component: component_45stub1mojfLEhoX
  }
]